import dynamic from 'next/dynamic';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslations } from 'next-intl';
import { AvatarMenu } from './AvatarMenu/AvatarMenu';
import { Link, usePathname } from '@/navigation';
import appConst from '~/const/appConst';
import { handleRedirectToLogin, isUserDefined } from '~/utils/utils';
import { Loader } from '~/components/commons/Loader/Loader';
import { Logo } from '~/components/commons/Logo/Logo';
import { EnvRibbon } from '~/components/commons/EnvRibbon/EnvRibbon';
import { MainColumn } from '~/components/commons/MainColumn/MainColumn';
import { env } from '~/const/env';
import {
  authenticatedSelector,
  historyUrlSelector,
  userSelector,
} from '~/selectors/selectors';

const DynamicCGUDialog = dynamic(
  () =>
    import('~/components/commons/CGUDialog/CGUDialog').then(
      res => res.CGUDialog,
    ),
  {
    ssr: false,
  },
);

export const Header = () => {
  const t = useTranslations('SEO');
  const pathname = usePathname();

  const authenticated = useSelector(authenticatedSelector);
  const user = useSelector(userSelector);
  const historyUrl = useSelector(historyUrlSelector);

  const [isLoading, setIsLoading] = useState(false);

  const isHomePage: boolean = pathname === appConst.staticRoutes.index;

  const handleClick = () => {
    handleRedirectToLogin(historyUrl);
  };

  const headerLogoLink = (
    <Link
      href={appConst.staticRoutes.index}
      className="logo"
      title={t('NAME')}
      prefetch={false}
    >
      <Logo />
    </Link>
  );

  return (
    <>
      <header id="header" data-testid="header" className="header">
        <MainColumn>
          <div className="header__nav">
            <div className="header__nav-logo">
              {isHomePage
                ? (
                  <h1 className="header__logo">
                    <span className="logo">
                      <Logo />
                      <span className="header__logo-baseline">
                        {t('NAME')}
                      </span>
                    </span>
                  </h1>
                  )
                : (
                  <div className="header__logo">{headerLogoLink}</div>
                  )}
            </div>
            <div className="header__nav-user">
              {isUserDefined(user) && !isLoading
                ? (
                  <AvatarMenu
                    setIsLoading={setIsLoading}
                    authenticated={authenticated}
                    handleRedirectToLogin={handleClick}
                  />
                  )
                : (
                  <Loader size="medium" />
                  )}
            </div>
          </div>
        </MainColumn>
      </header>
      {env.ENVIRONMENT !== 'prod' && <EnvRibbon />}
      {!pathname.includes('terms-of-service') && <DynamicCGUDialog />}
    </>
  );
};

export interface GetAuthenticatedDispatcherPostData {
  code?: string;
  state?: string;
}
